import axios from "axios";

// Base URLs for Hippo APIs
export const baseUrlHippo = `https://api.hellohippo.com/V2/`

// Request headers for Hippo API including an API key from environment variables
const requestHeaderHippo = {
  "Content-Type": "application/json",
  "api_key": process.env.REACT_APP_HIPPO_KEY
};

// Create an axios instance for Hippo API
export const httpClient = axios.create({
  baseURL: baseUrlHippo,
  headers: requestHeaderHippo,
});

// API keys from environment variables
export const GoogleAPIKeyClient = process.env.REACT_APP_GOOGLE_API_KEY;
export const IPAPIkey = process.env.REACT_APP_IPAPI_KEY;

// Base URLs from environment variables for Java backend services
export const baseUrlJava = process.env.REACT_APP_BASE_URL_JAVA;
export const baseUrlJavaRxSense = process.env.REACT_APP_BASE_URL_JAVA_RXSENSE;
export const baseUrlConnective = process.env.REACT_APP_BASE_URL_CONNECTIVE;

// Default request headers for Java API requests
const requestHeaderJava = {
  "Content-Type": "application/json",
};

// Create an axios instance for Java backend services
export const httpClientJava = axios.create({
  baseURL: baseUrlJava,
  headers: requestHeaderJava,
});

// Create an axios instance for Java backend services specific to RxSense
export const httpClientJavaRxSense = axios.create({
  baseURL: baseUrlJavaRxSense,
  headers: requestHeaderJava,
});

// Create an axios instance for Connective API
export const httpClientConnective = axios.create({
  baseURL: baseUrlConnective,
  headers: requestHeaderJava,
});

// Add a request interceptor for Java API to add the Authorization header
httpClientJava.interceptors.request.use(
  config => {
    // Skip adding token for certain routes (like login and password reset)
    if (!(config?.url === "patient/login")
      && !(config.url.includes("patient/forgetPassword?username="))
      && !(config.url.includes("signUp"))
      && !(config.url.includes("patient/validateOtp"))
      && !(config.url.includes("patient/generateOtp"))
      && !(config.url.includes("patient/getUIFlags"))) {
      const token = JSON.parse(localStorage.getItem('user')).token; // Get token from localStorage
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token; // Add token to Authorization header
      }
    }
    config.headers['Content-Type'] = 'application/json'; // Set Content-Type header
    return config; // Return the modified config
  },
  error => {
    Promise.reject(error); // Handle request errors
  }
);

// Add a request interceptor for RxSense Java API to add the Authorization header
httpClientJavaRxSense.interceptors.request.use(
  config => {
    // Skip adding token for specific routes (like login and password reset)
    if (!(config?.url === "patient/login")
      && !(config.url.includes("patient/forgetPassword?username="))
      && !(config.url.includes("signUp"))
      && !(config.url.includes("patient/validateOtp"))
      && !(config.url.includes("patient/generateOtp"))
    ) {
      const token = JSON.parse(localStorage.getItem('user')).token; // Get token from localStorage
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token; // Add token to Authorization header
      }
    }
    config.headers['Content-Type'] = 'application/json'; // Set Content-Type header
    return config; // Return the modified config
  },
  error => {
    Promise.reject(error); // Handle request errors
  }
);

// Add a request interceptor for Connective API to add the Authorization header
httpClientConnective.interceptors.request.use(
  config => {
    // Skip adding token for specific routes (like login and password reset)
    if (!(config?.url === "patient/login")
      && !(config.url.includes("patient/forgetPassword?username="))
      && !(config.url.includes("signUp"))
      && !(config.url.includes("patient/validateOtp"))
      && !(config.url.includes("patient/generateOtp"))
    ) {
      const token = JSON.parse(localStorage.getItem('user')).token; // Get token from localStorage
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token; // Add token to Authorization header
      }
    }
    config.headers['Content-Type'] = 'application/json'; // Set Content-Type header
    return config; // Return the modified config
  },
  error => {
    Promise.reject(error); // Handle request errors
  }
);

// Add a response interceptor to handle Java API responses
httpClientJava.interceptors.response.use((response) => {
  // Handle token refresh logic if necessary (commented out code)
  // if (response?.data?.statusCode === 412) {
  //   const originalRequest = response.config;
  //   const userDetail = JSON.parse(localStorage.getItem('user'));
  //   return httpClientJava.get('patient/refreshToken', {
  //     headers: { "Authorization": userDetail?.refreshToken, "Content-Type": "application/json" }
  //   }).then(res => {
  //     if (res?.data) {
  //        userDetail.token = [...res?.data?.token];
  //        userDetail.refreshToken = [...res?.data?.refreshToken];
  //       localStorage.setItem('user', JSON.stringify(userDetail));
  //       httpClientJava.defaults.headers.common['Authorization'] = 'Bearer' + res?.data?.token;
  //       return httpClientJava(originalRequest);
  //     }
  //   })
  // }
  return response; // Return the response as-is
},
  function (error) {
    if(error.response.data.statusCode === 412) window.location.href = "/";    
    return Promise.reject(error); // Handle response errors
  }
);

// Add a response interceptor for RxSense Java API
httpClientJavaRxSense.interceptors.response.use((response) => {
  // Handle token refresh logic if necessary (commented out code)
  // if (response?.data?.statusCode === 412) {
  //   const originalRequest = response.config;
  //   const userDetail = JSON.parse(localStorage.getItem('user'));
  //   return httpClientJava.get('patient/refreshToken', {
  //     headers: { "Authorization": userDetail?.refreshToken, "Content-Type": "application/json" }
  //   }).then(res => {
  //     if (res?.data) {
  //        userDetail.token = [...res?.data?.token];
  //        userDetail.refreshToken = [...res?.data?.refreshToken];
  //       localStorage.setItem('user', JSON.stringify(userDetail));
  //       httpClientJava.defaults.headers.common['Authorization'] = 'Bearer' + res?.data?.token;
  //       return httpClientJava(originalRequest);
  //     }
  //   })
  // }
  return response; // Return the response as-is
},
  function (error) {
    if(error.response.data.statusCode === 412) window.location.href = "/";    
    return Promise.reject(error); // Handle response errors
  }
);