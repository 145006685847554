import React from 'react'
import tick from "../assets/images/check.png";
import cross from "../assets/images/cross.png";

const [height, width] =  [13, 13];

function TickCross(formik, field, classToApply) {
  return (
    <div>
    {formik.errors[field] && formik.touched[field] && (
        <span className={classToApply}><img src={cross} height={height} width={width}/></span>
    )}
    {!formik.errors[field]  && formik.touched[field]  && (
        <span className={classToApply}><img src={tick} height={height} width={width}/></span>
    )}
    </div>
  )
}

export default TickCross;