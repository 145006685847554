import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider } from "./searchPharmacy"; // Divider component for visual separation
import faGreaterThan from "../assets/images/RxLink assets/new assets/arrow/side.svg"; // Icon import
import { setShowPahrmacyMap, setResetPwdModal, setFromDrawer, setShowCoupon, setPharmacyList } from "../reducers/medication"; // Redux actions
import { httpClientJava } from "../environment"; // API client
import { useGetUser } from "../utilities/useGetUser"; // Custom hook to get user data

// Main Drawer component, receives `totalSavings` as a prop
const Drawer = ({ totalSavings }) => {
  const navigate = useNavigate(); // Hook for navigation
  const dispatch = useDispatch(); // Hook to dispatch actions
  const user = useGetUser(); // Retrieve user information

  const [name, setName] = useState(""); // State for storing user name
  const pharmacyList = useSelector((state) => state.medication.pharmacyList); // Get pharmacy list from Redux
  const userPharmacy = user?.pharmacy; // Get user pharmacy info from user object

  // Set pharmacy list in Redux if empty and user has a pharmacy
  useEffect(() => {
    if (pharmacyList.length === 0 && userPharmacy?.userPharmacySeqNo) {
      dispatch(setPharmacyList([userPharmacy]));
    }
  }, [userPharmacy]);

  // Get selected default pharmacy
  const selectedPharmacy = pharmacyList.length > 0 ? pharmacyList.filter(item => !!item.isDefault) : [];

  const insuranceInfo = useSelector((state) => state.global.insuranceInfo); // Get insurance info from Redux

  // Use effect to set the name from various sources (user, insurance info, etc.)
  useEffect(() => {
    if (insuranceInfo || user) {
      if (user?.firstName && user.firstName.trim() !== "") setName(user.firstName);
      else if (insuranceInfo?.firstName && insuranceInfo.firstName.trim() !== "") setName(insuranceInfo.firstName);
      else if (user?.middleName && user.middleName.trim() !== "") setName(user.middleName);
      else if (insuranceInfo?.middleName && insuranceInfo.middleName.trim() !== "") setName(insuranceInfo.middleName);
      else if (user?.lastName && user.lastName.trim() !== "") setName(user.lastName);
      else if (insuranceInfo?.lastName && insuranceInfo.lastName.trim() !== "") setName(insuranceInfo.lastName);
    }
  }, [insuranceInfo, user]);

  // Function to handle logout
  const logout = async () => {
    dispatch(setShowCoupon(false)); // Close coupon if open
    navigate("/"); // Navigate to home
    await httpClientJava.post("patient/logout", { appUserSeqNo: user.appUserSeqNo })
      .then((response) => {
        // Handle successful logout
      })
      .catch((err) => console.log({ err })); // Handle error
    localStorage.clear(); // Clear localStorage
    sessionStorage.clear(); // Clear sessionStorage
  };

  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight">
      {/* Drawer Header */}
      <div className="offcanvas-header">
        <div className="medicine_icon pharma_icon back_icon my-4 mx-0"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          {/* Close button */}
          <a href="#" onClick={(e) => e.preventDefault()} className="txtDecoration">
            <i aria-label="Close my account section" className="fas fa-angle-right"></i>
          </a>
        </div>
      </div>
      {/* Drawer Body */}
      <div className="offcanvas-body">
        <div className="right-aligned-item">
          <span>Hello {name}</span> {/* Display user name */}
        </div>
        {totalSavings > 0 && (
          <div className="right-aligned-item">
            {/* Display total savings if greater than 0 */}
            <span>You have saved <strong>$</strong>
              <span className="bold-text">
                {totalSavings.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </span>
          </div>
        )}
        <Divider /> {/* Visual divider */}
        <div style={{ margin: "18px 0px 0px 0px" }}>
          {/* Drawer items for account management, navigation, etc. */}
          <DrawerItem title="My account" iconToRender={faGreaterThan} onClickHandler={() => navigate("/myAccount")} />
          <DrawerItem
            title={selectedPharmacy.length > 0 ? selectedPharmacy[0]["pharmacyName"] : "My Pharmacy"}
            iconToRender={faGreaterThan} subText={selectedPharmacy.length > 0 ? selectedPharmacy[0]["address"] : ""}
            onClickHandler={() => {
              navigate("/medications");
              dispatch(setShowPahrmacyMap(true));
              dispatch(setShowCoupon(false));
              dispatch(setFromDrawer(true));
            }}
          />
          <DrawerItem title="Savings History" iconToRender={faGreaterThan} onClickHandler={() => navigate("/savings")} />
          <DrawerItem title="Reset Password" iconToRender={faGreaterThan} onClickHandler={() => dispatch(setResetPwdModal(true))} />
        </div>
        <div className="logout-item">
          {/* Logout item */}
          <DrawerItem title="Sign out" onClickHandler={logout} />
        </div>
      </div>
    </div>
  );
};

export default Drawer;

// Drawer item component with title, icon, subtext, and click handler
const DrawerItem = ({ title, iconToRender, subText, onClickHandler }) => {
  const ref = useRef(null); // Use ref for DOM manipulation

  // Ensure max-width is set on the button
  useLayoutEffect(() => {
    ref.current.style.setProperty("max-width", "100%", "important");
  }, []);

  return (
    <div>
      <button className="btn_default text-start"
        ref={ref}
        onClick={(e) => {
          e.preventDefault(); // Prevent Bootstrap from interfering
          if (!!onClickHandler) {
            onClickHandler(); // Trigger click handler if defined
          }
        }}
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
      >
        <div className="row">
          {/* Title and subtext rendering */}
          <span className="col-10 fw-bold">
            {title}
            {!!subText && <br />} {!!subText && <span style={{ fontSize: 12 }} className="fw-normal">{subText}</span>}
          </span>
          {!!iconToRender && (
            <span className="col-2 icon-forward">
              {/* Icon rendering */}
              <i aria-label={"open " + title}>
                <img src={iconToRender} alt="forward" />
              </i>
            </span>
          )}
        </div>
      </button>
    </div>
  );
};
