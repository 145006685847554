import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import Countly from 'countly-sdk-web';
import * as Sentry from "@sentry/react";
import './assets/css/styles.css'
import CustomErrorBoundary from './CustomErrorBoundary';


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
    }),
    Sentry.replayIntegration()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event, hint) {
    if (hint.originalException && hint.originalException.name === "SecurityError") {
      return null; // Ignore storage-related SecurityErrors
    }
    return event;
  }
});


// Initializing countly sdk for analytics purpose
// Exposing Countly to the DOM as a global variable
try {
  window.Countly = Countly;
  Countly.init({
    app_key: process.env.REACT_APP_COUNTLY_ID,
    url: 'https://prodanalytics000101.rxlink.com/',
    debug: false,
    storage: 'cookie' // Fallback to cookies instead of localStorage
  });

  // Check if storage is accessible
  function isStorageAvailable(type) {
    try {
      let storage = window[type];
      let testKey = "__test__";
      storage.setItem(testKey, "test");
      storage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  if (!isStorageAvailable("localStorage")) {
    console.warn("localStorage is blocked. Countly may not function properly.");
  }

  // actions Countly is currently tracking
  Countly.q.push(['track_sessions']);
  Countly.q.push(['track_scrolls']);
  Countly.q.push(['track_clicks']);
  Countly.q.push(['track_links']);
  Countly.q.push(["track_errors"]);

} catch (error) {
  console.warn("Countly initialization failed:", error);
}


// if environment is production we want to hide console
if (process.env.REACT_APP_ENV === 'PRODUCTION' || process.env.REACT_APP_ENV === 'STAGING') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}


// We are wrapping App component inside redux store and Sentry Error boundary component
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CustomErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </CustomErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();