import React, { useState, useRef, useEffect } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef } from "react";
import TickCross from "../../utilities/TickCross";
import cross from "../../assets/images/cross.png";
import tick from "../../assets/images/check.png";
import { useWindowWidth } from "../searchPharmacy/useWindowWidth";

const initialState = {
    month: "",
    day: "",
    year: ""
}

const DateOfBirth = ({ formik, fieldName, enabled, patientRecordDob, id, errorMonth, setErrorMonth, errorDay, setErrorDay, errorYear, setErrorYear, deskClass, mobileClass }) => {
    const pickerRef = useRef(null);
    const [error, setError] = useState(null);
    // const [errorMonth, setErrorMonth] = useState("12");
    // const [errorYear, setErrorYear] = useState("12");
    // const [errorDay, setErrorDay] = useState("12");
    const [manualDate, setManualDate] = useState(initialState);

    const { windowWidth } = useWindowWidth();

    useEffect(() => {
        if (pickerRef.current && pickerRef.current.input)
            pickerRef.current.input.readOnly = true;
    }, [pickerRef])

    const handleManualChange = (e) => {
        const { name, value } = e.target;

        const newValue = value != "" && parseInt(value);
        let error = false
        switch (name) {
            case "month":
                if (newValue > 12) {
                    setError("Please check that you are inputting a valid month and try again.")
                    setErrorMonth(true)
                    error = true
                    break;
                }
                if (value == "") {
                    setErrorMonth("121")
                    error = true
                    break;
                }
                else {
                    setErrorMonth(false)
                    break;
                }

            case "day":
                if (newValue > 31) {
                    setError("Please check that you are inputting a valid day and try again.");
                    setErrorDay(true);
                    error = true
                    break;
                }
                if (value == "") {
                    setErrorDay("121")
                    error = true
                    break;
                }
                else {
                    setErrorDay(false)
                    break;
                }
            case "year":
                if ((newValue < 1900 || newValue > new Date().getFullYear()) && value.length >= 4) {
                    setError("Please check that you are inputting a valid year and try again.");
                    setErrorYear(true);
                    error = true
                    break;
                }
                if (value == "") {
                    setErrorYear("121")
                    error = true
                    break;
                }
                else if (value.length == 4) {
                    setErrorYear(false)
                    break;
                }
            default:
                break;
        }

        if (!error) {
            const newManualDate = { ...manualDate, [name]: value }
            const { month, day, year } = newManualDate;
            if (Number(month) && Number(day) && Number(year) && year.length === 4) {
                const date = new Date(year, month - 1, day);
                formik.setFieldValue(fieldName, date)
            } else {
                formik.setFieldValue(fieldName, "")
            }
            setManualDate(newManualDate)
        } else {
            formik.setFieldValue(fieldName, "")
            setManualDate(initialState);
        }
    }

    let invalidCheck = errorDay == true || errorMonth == true || errorYear == true;


    const addleadingZero = (value) => {
        if (value.length === 1) {
            return "0" + value
        }
        return value
    }

    const handleCalendarDateChange = (date) => {
        const newManualDate = { month: addleadingZero(String(date.getMonth() + 1)), day: addleadingZero(String(date.getDate())), year: String(date.getFullYear()) }
        setManualDate(newManualDate)
    }

    const CustomInput = forwardRef((props) => {
        return (
            <a href="javascript:void(0);" onClick={props.onClick} aria-label="calendar"><FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: 30, cursor: enabled && 'pointer', color: "black" }} tabIndex={-1} aria-label={null} /></a>
        );
    });

    useEffect(() => {
        if (formik.values[fieldName]) {
            if (patientRecordDob && patientRecordDob instanceof Date && !equalDates(patientRecordDob, formik.values[fieldName])) {
                setError("Please check that you are inputting your date of birth in MM/DD/YYYY format and try again.")
                setManualDate(initialState);
                return;
            }
            handleCalendarDateChange(formik.values[fieldName])
            setError(null);
        }
    }, [formik.values[fieldName]])

    const equalDates = (date1, date2) => {
        return date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear()
    }
    const tabIndex = -1

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                //setPickerIsOpen(false);
                console.log({ pickerRef })
                pickerRef?.current && pickerRef.current.setOpen(false)
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    let dayCheck = (formik.errors[fieldName] && formik.touched[fieldName]) && (errorDay || errorDay == "12");
    let monthCheck = (formik.errors[fieldName] && formik.touched[fieldName]) && (errorMonth || errorMonth == "12");
    let yearCheck = (formik.errors[fieldName] && formik.touched[fieldName]) && (errorYear || errorYear == "12");

    let dateCheck = dayCheck || monthCheck || yearCheck;

    let c1 = (formik.errors[fieldName] && formik.touched[fieldName] && !error && enabled) ? true : false;
    let c2 = (error && enabled) ? true : false;


    return (
        <div>
            <div style={{ display: 'flex', position: "relative", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '1.5px', marginBottom: '4px' }}>
                <LabeledInput
                    label={"Month"}
                    fieldName="month"
                    className={
                        `form-control  custom-form-control ${!enabled && "disabled-input"}` +
                        ((formik.errors[fieldName] && formik.touched[fieldName]) && (errorMonth || errorMonth == "121")
                            ? " is-invalid"
                            : "")
                    }
                    placeholder="MM"
                    onChange={handleManualChange}
                    value={manualDate.month}
                    disabled={!enabled}
                    id={id + "monthInput"}
                    ariaLabel={"Month"}
                    formik={formik}
                    enabled={enabled}
                    error={(formik.errors[fieldName] && formik.touched[fieldName]) && (errorMonth || errorMonth == "121")}
                    touched={formik.touched[fieldName]}
                />
                <LabeledInput
                    label={"Day"}
                    fieldName="day"
                    className={
                        `form-control  custom-form-control ${!enabled && "disabled-input"}` +
                        ((formik.errors[fieldName] && formik.touched[fieldName]) && (errorDay || errorDay == "121")
                            ? " is-invalid"
                            : "")
                    }
                    placeholder="DD"
                    onChange={handleManualChange}
                    value={manualDate.day}
                    disabled={!enabled}
                    id={id + "dayInput"}
                    ariaLabel={"Day"}
                    formik={formik}
                    enabled={enabled}
                    error={(formik.errors[fieldName] && formik.touched[fieldName]) && (errorDay || errorDay == "121")}
                    touched={formik.touched[fieldName]}
                />
                <LabeledInput
                    label={"Year"}
                    fieldName="year"
                    className={
                        `form-control  custom-form-control ${!enabled && "disabled-input"}` +
                        ((formik.errors[fieldName] && formik.touched[fieldName]) && (errorYear || errorYear == "121")
                            ? " is-invalid"
                            : "")
                    }
                    placeholder="YYYY"
                    onChange={handleManualChange}
                    value={manualDate.year}
                    disabled={!enabled}
                    id={id + "yearInput"}
                    ariaLabel={"Year"}
                    formik={formik}
                    enabled={enabled}
                    error={(formik.errors[fieldName] && formik.touched[fieldName]) && (errorYear || errorYear == "121")}
                    touched={formik.touched[fieldName]}

                />
                <div style={{ width: "8%" }} className="forget_input_eye">
                    {enabled && <DatePicker
                        ref={pickerRef}
                        name={fieldName}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        minDate={new Date(1900, 0, 1)}
                        maxDate={new Date()}
                        className={
                            `form-control  custom-form-control ${!enabled && "disabled-input"}` +
                            (formik.errors[fieldName] && formik.touched[fieldName]
                                ? " is-invalid"
                                : "")
                        }
                        customInput={<CustomInput />}
                        onChange={date => {
                            formik.setFieldValue(fieldName, date)
                        }}
                        selected={formik.values[fieldName]}
                        placeholderText="Date of Birth*"
                        disabled={!enabled}
                        id={id + "datePickerInput"}
                        ariaLabel={"Date Picker"}
                    // onKeyDown={handleKeyDown}
                    // setOpen={(isOpen) => setOpen(isOpen)} // Set the open state
                    />}

                </div>

            </div>

            {c1 && !c2 && <div className="date-picker-feedback invalid-feedback" role="alert" id="dobError" aria-live="off">
                {" "}
                {formik.errors[fieldName] && formik.touched[fieldName] && !error && enabled
                    ? formik.errors[fieldName]
                    : null}{" "}

            </div>}
            {!c1 && c2 &&
                <div className="date-picker-feedback invalid-feedback" role="alert" id="dobError" aria-live="off">
                    {error && enabled ? error : null}
                </div>
            }
            {
                ((deskClass || mobileClass) && ((!formik.errors[fieldName] && formik.touched[fieldName])) && !error && enabled) && <span className={windowWidth > 800 ? deskClass : mobileClass}><img src={tick} height={13} width={13} /></span>
            }
            {
                ((deskClass || mobileClass) && formik.errors[fieldName] && formik.touched[fieldName] && !error && enabled) && <span className={windowWidth > 800 ? deskClass : mobileClass}><img src={cross} height={13} width={13} /></span>
            }
            {
                (deskClass || mobileClass) && error && enabled && <span className={windowWidth > 800 ? deskClass : mobileClass}><img src={cross} height={13} width={13} /></span>
            }
        </div>
    );
}

export default DateOfBirth;


const LabeledInput = ({ fieldName, className, placeholder, onChange, value, disabled, label, id, ariaLabel, formik, enabled, error, touched }) => {
    const announceErrorMessage = (id, message) => {
        const liveRegion = document.getElementById(id);
        if (liveRegion) {
            liveRegion.innerText = message;
            liveRegion.setAttribute('aria-live', 'assertive');
        }
    };



    const hideErrorMessage = (id, message) => {
        const liveRegion = document.getElementById(id);
        if (liveRegion) {
            // liveRegion.innerText = 'Password must be 8 characters long and contain a mix of letters and numbers.';
            liveRegion.setAttribute('aria-live', 'off');
        }
    };
    return (
        <div style={{ width: "27%", position: 'relative' }}>
            <label htmlFor={id} className="input-with-label" for={id}>{label}</label>
            <input
                type="number"
                inputmode="decimal"
                name={fieldName}
                className={className}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                disabled={disabled}
                id={id}
                // aria-label={ariaLabel}
                aria-describedby="dobError"
                onFocus={() => announceErrorMessage('dobError', 'Required')}
                onBlur={() => hideErrorMessage('dobError')}
            />
            {/* {error ? (
                <div className="badge bg-danger rounded-pill error-circle" style={{ right: -24, top: 15 }}>x</div>
            ) : touched ? <span className="badge bg-success rounded-pill error-circle">✔</span> :null} */}
        </div>
    );
};