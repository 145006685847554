import MaskedInput from "react-text-mask";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import TickCross from "../../utilities/TickCross";

const phoneNumberMask = [
  "(", /[1-9]/, /\d/, /\d/, ")",
  " ", /\d/, /\d/, /\d/, "-",
  /\d/, /\d/, /\d/, /\d/
];

export const UsernameField = ({ formik, id, ariaLabel, inputRef }) => {
  const [username, setUsername] = useState(formik.values.username);

  useEffect(() => {
    formik.setFieldValue("username", username);
  }, [username]);

  useEffect(() => {
    setTimeout(() => formik.validateForm(), 200);
  }, []);

  return (
    <div className="row g-0">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="mb-1 form-group forget_input_eye">
          <MaskedInput
            name="username"
            type="tel"
            className={`form-control custom-form-control login_input` +
              (formik.errors.username && formik.touched.username ? " is-invalid" : "")
            }
            id={id}
            mask={phoneNumberMask}
            placeholder="Mobile phone number"
            value={formik.values.username}
            onChange={formik.handleChange}
            ref={inputRef}
            aria-label={ariaLabel}
          />
          {formik.errors.username && formik.touched.username && (
            <div className="invalid-feedback" aria-live="off" id="usernameError">
              {formik.errors.username}
            </div>
          )}
          {TickCross(formik, "username", "error-circle")}
        </div>
      </div>
    </div>
  );
};

export const usernameValidation =
    Yup.string()
        .required("Phone number is required")
        .test('phone-number-length', 'Invalid phone number', (value, ctx) => {
            const numericValue = value.replace(/\D/g, '');
            if (numericValue == "") {
                return ctx.createError({ message: 'Phone number is required' })
            }
            else {
                return numericValue.length === 10;
            }
        })



// : Yup.string()
//     .required("Phone number / Email address is required")
//     .email("Invalid email address")