import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { saveInsurance } from "../../reducers/submitInsurance";
import "react-toastify/dist/ReactToastify.css";
import { motion, AnimatePresence } from "framer-motion";
import tabHealth from "../../assets/images/tab-health.svg";
import { setShowPahrmacyMap } from "../../reducers/medication";
import { fetchInsuranceInfo } from "../../reducers/getInsuranceInfo";
import { setInsuranceInfo } from "../../reducers/global";
import { genderOptions } from "./content";
import "./insurance.css";
import 'react-datepicker/dist/react-datepicker.css';
import { successToast, errorToast } from "../toast/toast";
import DateOfBirth from "../Fields/DateOfBirth";
import { useStyleMediaQuery } from "../coupan/hooks";
import TickCross from "../../utilities/TickCross";
import { useWindowWidth } from "../searchPharmacy/useWindowWidth";
import { useGetUser } from "../../utilities/useGetUser";
import { store } from "../../store";
import { saveProfile } from "../../reducers/submitProfile"
import Spinner from 'react-bootstrap/Spinner';
import PopoverInsurance from "../Popover";

const Insurance = () => {
  // Manage loading state (true when data is being fetched or operation is in progress)
  const [loading, setLoading] = useState(false);

  // Hook to programmatically navigate between routes
  const navigate = useNavigate();

  // Redux dispatch hook to dispatch actions (e.g., for global state management)
  const dispatch = useDispatch();

  // Custom hook to retrieve user information
  const user = useGetUser();

  // State variable for HCDL checkbox  
  const [checked, setChecked] = useState(user?.formSubmitted ? true : false);

  // Destructure hasPharmacy property from user object to check if user has a pharmacy
  const hasPharmacy = user?.hasPharmacy;

  const hasInsurance = user?.hasInsurance;

  const userOptin = user?.optin;

  // Media query to detect if the screen width is less than or equal to 575px (mobile device)
  const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: 575 });

  // Custom hook to get the current window width
  const { windowWidth } = useWindowWidth();

  // Check if the device is a tablet (width between 767px and 992px)
  const isTab = windowWidth > 766 && windowWidth <= 992;

  // Check if the device is a desktop (width greater than 992px)
  const isDesktop = windowWidth > 992;

  // Check if the device is a phone (not a tablet or desktop)
  const isPhone = (isTab == false && isDesktop == false);

  // States to store error messages for date-related validation (month, year, day)
  const [errorMonth, setErrorMonth] = useState("121");
  const [errorYear, setErrorYear] = useState("121");
  const [errorDay, setErrorDay] = useState("121");


  const [errorOptin, setErrorOptin] = useState(false);

  // Determine if the current environment is production
  // const isProd = process.env.REACT_APP_ENV === "PRODUCTION" ? true : false;

  // get state from redux store
  const reduxState = store.getState();

  // get insurance information of user
  const insuranceInfo = reduxState.global.insuranceInfo;

  useEffect(() => {
    // If the user has a first name, set it in the form
    user?.firstName && formik.setFieldValue("firstName", user?.firstName);

    // If the user has a last name, set it in the form
    user?.lastName && formik.setFieldValue("lastName", user?.lastName);

    // If the user has a middle name, set it in the form
    user?.middleName && formik.setFieldValue("middleName", user?.middleName);

    // If the user has a gender, set it in the form
    user?.gender && formik.setFieldValue("gender", user?.gender);

    // Validate the DOB format (ensure it's 8 digits and can be parsed) and set it as a Date object
    if (/^\d{8}$/.test(user?.dob) && parseInt(user?.dob)) {
      const year = parseInt(user.dob.substr(0, 4));     // Extract year
      const month = parseInt(user.dob.substr(4, 2)) - 1; // Extract month (subtract 1 because months are 0-indexed in JS Date)
      const day = parseInt(user.dob.substr(6, 2));       // Extract day
      const convertedDate = new Date(year, month, day);  // Create a Date object
      formik.setFieldValue("dob", convertedDate);        // Set the date of birth in the form
    }

    // Fetch insurance info for the user and update form fields
    dispatch(fetchInsuranceInfo({ appUserSeqNo: user?.appUserSeqNo }))
      .unwrap()
      .then((res) => {
        if (res.data && res.data.length) {
          // Destructure necessary fields from the insurance data
          let {
            memberId,
            groupNumber,
            pcn,
            bin,
            firstName,
            lastName,
            middleName,
            dob,
            gender,
          } = res.data[0];

          // Dispatch the insurance info to update the state
          res.data[0] && dispatch(setInsuranceInfo(res.data[0]));

          // Set form field values with insurance data if available
          firstName && formik.setFieldValue("firstName", firstName);
          lastName && formik.setFieldValue("lastName", lastName);
          middleName && formik.setFieldValue("middleName", middleName);
          pcn && formik.setFieldValue("pcn", pcn);
          bin && formik.setFieldValue("bin", bin);
          groupNumber && formik.setFieldValue("groupNumber", groupNumber);
          gender && formik.setFieldValue("gender", gender);
          memberId && formik.setFieldValue("memberId", memberId);

          // Validate the insurance DOB format (ensure it's 8 digits and can be parsed) and set it as a Date object
          if (/^\d{8}$/.test(dob) && parseInt(dob)) {
            const year = parseInt(dob.substr(0, 4));     // Extract year
            const month = parseInt(dob.substr(4, 2)) - 1; // Extract month (subtract 1 because months are 0-indexed in JS Date)
            const day = parseInt(dob.substr(6, 2));       // Extract day
            const convertedDate = new Date(year, month, day);  // Create a Date object
            formik.setFieldValue("dob", convertedDate);        // Set the date of birth in the form
          }
        }
      });
  }, []); // Empty dependency array ensures this effect runs once when the component mounts


  const CopayProgram = () => {
    if (loading) return;
    submitInsuranceForm(formik?.values, true);
  };

  // Yup validation schema for insurance form
  const validationSchema = checked ?
    Yup.object().shape({
      firstName: Yup.string(),
      lastName: Yup.string(),
      bin: Yup.string(),
      pcn: Yup.string(),
      groupNumber: Yup.string(),
      memberId: Yup.string(),
      dob: Yup.string().when(['bin', 'pcn', 'memberId', 'groupNumber'], {
        is: (bin, pcn, memberId, groupNumber) => {
          if (bin || pcn || memberId || groupNumber) return true;
          else return false;
        },
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.min(0),
      }),
      gender: Yup.string().when(['bin', 'pcn', 'memberId', 'groupNumber'], {
        is: (bin, pcn, memberId, groupNumber) => {
          if (bin || pcn || memberId || groupNumber) return true;
          else return false;
        },
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.min(0),
      }),
    })
    : Yup.object().shape({
      firstName: Yup.string().required("Required"),
      middleName: Yup.string(),
      lastName: Yup.string().required("Required"),
      gender: Yup.string().required("Required"),
      dob: Yup.string().required("Required"),
      bin: Yup.string().required("Required"),
      pcn: Yup.string(),
      groupNumber: Yup.string().required("Required"),
      memberId: Yup.string().required("Required"),
    });

  const submitInsuranceForm = (data, skip = false) => {
    setLoading(true);
    let body = {
      firstName: !skip ? data?.firstName : insuranceInfo?.firstName ? insuranceInfo?.firstName : "",
      middleName: !skip ? data?.middleName : insuranceInfo?.middleName ? insuranceInfo?.middleName : "",
      lastName: !skip ? data?.lastName : insuranceInfo?.lastName ? insuranceInfo?.lastName: "",
      dob: !skip ? data.dob != "" ? String(data?.dob?.getFullYear()) + String(data?.dob?.getMonth() + 1).padStart(2, '0') + String(data?.dob?.getDate()).padStart(2, '0') : "" : insuranceInfo?.dob ? insuranceInfo?.dob: "",
      gender: !skip ? data?.gender : insuranceInfo?.gender ? insuranceInfo?.gender : "",
      bin: !skip ? data?.bin : insuranceInfo?.bin ? insuranceInfo?.bin : "",
      pcn: !skip ? data?.pcn : insuranceInfo?.pcn ? insuranceInfo?.pcn : "",
      groupNumber: !skip ? data?.groupNumber : insuranceInfo?.groupNumber ? insuranceInfo?.groupNumber : "",
      memberId: !skip ? data?.memberId : insuranceInfo?.memberId ? insuranceInfo?.memberId: "",
      appUserSeqNo: user?.appUserSeqNo,
    };
    let extendedBody = {
      insInfoSeqNo: insuranceInfo?.insInfoSeqNo,
      patientSeqNo: user?.patientSeqNo,
      address: insuranceInfo?.address,
      hasAlerts: false,
      hcdlEnrolled: user?.hcdlEnrolled,
      formSubmitted: true,
      optin: userOptin ? true : !skip ? checked : false,
      ...body
    }
    dispatch(saveProfile(extendedBody))
      .unwrap()
      .then((response) => {
        setLoading(false);
        if (response?.statusCode === 200 & response?.status === "OK")
          if (response?.statusCode === 200 & response?.status === "OK") {
            localStorage.setItem("user", JSON.stringify({
              ...user,
              firstName: response?.data?.firstName,
              lastName: response?.data?.lastName,
              dob: response?.data?.dob,
              gender: response?.data?.gender,
              optin: response?.data?.optin,
              formSubmitted: response?.data?.formSubmitted,
              pcn: response?.data?.pcn,
              bin: response?.data?.bin,
              groupNumber: response?.data?.groupNumber,
              memberId: response?.data?.memberId,
              hasInsurance: (response?.data?.groupNumber && response?.data?.memberId && response?.data?.bin) ? true : false
            }));
            successToast(response?.message, "showAlert");
            navigate("/medications");
          } else if (response?.status !== "OK") {
            errorToast(response?.message);
          }
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      gender: "",
      bin: "",
      pcn: "",
      groupNumber: "",
      memberId: "",
    },
    validationSchema,
    onSubmit: (data) => { //submit function for insurance form
      if (loading) return;

      if (hasInsurance && !checked) {
        setErrorOptin(true);
        return;
      }
      submitInsuranceForm(data);
    },
  });

  const { isSubmitting, isValidating } = formik;

  // useEffect to show focus on first invalid input field
  useEffect(() => {
    if (isSubmitting && isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const firstErrorField = Object.keys(formik.errors).find(
          (fieldName) => formik.touched[fieldName]
        );
        if (firstErrorField) {
          switch (firstErrorField) {
            case "dob":
              if (errorMonth || errorMonth == "121") {
                window.document.getElementById("insurancemonthInput")?.focus();
                break;
              }
              if (errorDay || errorDay == "121") {
                window.document.getElementById("insurancedayInput")?.focus();
                break;
              }
              if (errorYear || errorYear == "121") {
                window.document.getElementById("insuranceyearInput")?.focus();
                break;
              }
              break;
            case "firstName":
              window.document.getElementById("insuranceFirstNameInput")?.focus();
              break;
            case "lastName":
              window.document.getElementById("insuranceLastNameInput")?.focus();
              break;
            case "gender":
              window.document.getElementById("insuranceGenderInput")?.focus();
              break;
            case "bin":
              window.document.getElementById("insuranceBinInput")?.focus();
              break;
            case "groupNumber":
              window.document.getElementById("insuranceGroupNumberInput")?.focus();
              break;
            case "memberId":
              window.document.getElementById("insuranceMemberIdInput")?.focus();
              break;
            default:
              break;
          }
        }
      }
    }
  }, [isSubmitting, isValidating]);

  // Announce Error messages for screen readers
  const announceErrorMessage = (id, message) => {
    const liveRegion = document.getElementById(id);
    if (liveRegion) {
      liveRegion.innerText = message;
      liveRegion.setAttribute('aria-live', 'assertive');
    }
  };

  // Hide Error messages for screen readers
  const hideErrorMessage = (id, message) => {
    const liveRegion = document.getElementById(id);
    if (liveRegion) {
      liveRegion.setAttribute('aria-live', 'off');
    }
  };

  return (
    <div>
      <AnimatePresence mode="wait">
        <section className="fadeAni" >
          <div className="row g-0">
            <div className="col-sm-12 col-md-12 col-lg-12 ">
              <div className="">&nbsp;</div>
            </div>
          </div>
          <div>
            <div className="row g-0" style={{ marginTop: "3vw" }}>
              <div className="col-12 col-sm-12 col-md-12 col-lg-5 p-0 autoMargin">
                <div className="vector-image "  >
                  <motion.img
                    key={tabHealth}
                    initial={{ opacity: 0, x: 500 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0 }}
                    src={tabHealth}
                    transition={{ duration: 0.8, type: "tween" }}
                    className="img-fluid  Vector"
                    alt=""
                  />
                </div>
              </div>
              <div
                className="col-12 col-sm-12 col-md-12 col-lg-7 paddingZero autoMargin"
                style={{ zIndex: 100 }}
              >
                <div className="">
                  <motion.div
                    className="insurance_inner_main insurance-padding"
                    initial={{ scale: 0.8 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <form onSubmit={formik.handleSubmit} id="insuranceForm">
                      <div className="row" id="cc-info-div">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <motion.div
                            className="insurance_inner"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.7 }}
                          >
                            <div className="row webView">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                {
                                  !hasPharmacy && <div className="page_counter" style={{ marginTop: -30, paddingBottom: 20 }}>
                                    <h4 style={{ fontSize: 14 }}>1</h4>
                                    <motion.h3
                                      className="active"
                                      initial={{ scale: 0.5 }}
                                      animate={{ scale: 1 }}
                                      transition={{ duration: 1 }}
                                    >
                                      2
                                    </motion.h3>
                                    <h4 style={{ fontSize: 14 }}>3</h4>
                                  </div>
                                }
                              </div>
                            </div>
                            {!hasInsurance && <div role="alert">
                              <motion.div
                                className="row"
                                initial={{ x: 300 }}
                                animate={{ x: 0 }}
                                transition={{ duration: 0.8 }}
                              >
                                <div className={`col-12 col-sm-12 col-md-12 col-lg-12 ${!hasPharmacy && "mt-4"}`}>
                                  <h1>Do you have health insurance?</h1>
                                </div>
                              </motion.div>
                              <motion.div
                                className="row"
                                initial={{ x: 300 }}
                                animate={{ x: 0 }}
                                transition={{ duration: 0.8, delay: 0.15 }}
                              >
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <p>
                                    If you have your insurance card, please add your information below and we will present the best medication options
                                    for you based on available information about your insurance plan.
                                  </p>
                                  <p>
                                    Please use the same information as it appears
                                    on your insurance card:
                                  </p>
                                </div>
                              </motion.div>
                            </div>}
                            {!hasInsurance && <motion.div
                              className="row g-1"
                              initial={{ x: 300, opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              transition={{ duration: 0.8, delay: 0.25 }}
                              exit={{ x: 300 }}
                            >
                              <div className="col-6 col-sm-3 col-md-4 col-lg-4 form-group forget_input_eye">
                                <input
                                  type="text"
                                  name="firstName"
                                  style={{ width: "90%" }}
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.firstName && formik.touched.firstName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="First Name*"
                                  onChange={formik.handleChange}
                                  value={formik.values.firstName}
                                  id="insuranceFirstNameInput"
                                  aria-label="First Name"
                                  aria-describedby="firstNameError"
                                  onFocus={() => announceErrorMessage('firstNameError', 'Required')}
                                  onBlur={() => hideErrorMessage('firstNameError')}
                                />
                                {formik.errors.firstName && formik.touched.firstName &&
                                  <div className="invalid-feedback" aria-live="off" id="firstNameError">
                                    {" "}
                                    {formik.errors.firstName && formik.touched.firstName
                                      ? formik.errors.firstName
                                      : null}{" "}
                                  </div>
                                }
                                {TickCross(formik, "firstName", "error-circle-insurance")}
                              </div>
                              <div className="col-6 col-sm-3 col-md-4 col-lg-4 form-group forget_input_eye">
                                <input
                                  type="text"
                                  name="middleName"
                                  style={{ width: "90%" }}
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.middleName && formik.touched.middleName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Middle Name"
                                  onChange={formik.handleChange}
                                  value={formik.values.middleName}
                                  id="insuranceMiddleNameInput"
                                  aria-label="Middle Name"
                                />
                                <div className="invalid-feedback" role="alert">
                                  {" "}
                                  {formik.errors.middleName && formik.touched.middleName
                                    ? formik.errors.middleName
                                    : null}
                                </div>
                              </div>
                              <div className="col-6 col-sm-3 col-md-4 col-lg-4 form-group forget_input_eye">
                                <input
                                  type="text"
                                  name="lastName"
                                  style={{ width: "90%" }}
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.lastName &&
                                      formik.touched.lastName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Last Name*"
                                  onChange={formik.handleChange}
                                  value={formik.values.lastName}
                                  id="insuranceLastNameInput"
                                  aria-label="Last Name"
                                  onFocus={() => announceErrorMessage('lastNameError', 'Required')}
                                  onBlur={() => hideErrorMessage('lastNameError')}
                                />
                                {formik.errors.lastName &&
                                  formik.touched.lastName && <div className="invalid-feedback" aria-live="off" id="lastNameError">
                                    {" "}
                                    {formik.errors.lastName &&
                                      formik.touched.lastName
                                      ? formik.errors.lastName
                                      : null}
                                  </div>}
                                {TickCross(formik, "lastName", "error-circle-insurance")}
                              </div>
                              {isMobile && <div className="col-6 col-sm-3 col-md-4 col-lg-4 form-group forget_input_eye">
                                <select
                                  name="gender"
                                  style={{ color: formik.values.gender === "" && "gray", width: "90%" }}
                                  defaultValue=""
                                  className={
                                    "form-select  custom-form-control custom-form-select" +
                                    (formik.errors.gender && formik.touched.gender
                                      ? " is-invalid form-select-is-invalid"
                                      : "")
                                  }
                                  onChange={formik.handleChange}
                                  value={formik.values.gender}
                                  id="insuranceGenderInput"
                                  aria-label="Birth Sex"
                                  onFocus={() => announceErrorMessage('genderError', 'Required')}
                                  onBlur={() => hideErrorMessage('genderError')}
                                >
                                  <option value="" hidden disabled defaultValue="">Birth Sex*</option>
                                  {genderOptions.map(option => (
                                    <option key={option.value} value={option.value} style={{ color: "black" }}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                                {formik.errors.gender && formik.touched.gender && <div className="invalid-feedback" role="alert" id="genderError" aria-live="off">
                                  {" "}
                                  {formik.errors.gender && formik.touched.gender
                                    ? formik.errors.gender
                                    : null}{" "}
                                </div>}
                                {TickCross(formik, "gender", "error-circle-insurance")}
                              </div>}
                            </motion.div>}
                            {!hasInsurance && <motion.div
                              className="row g-1"
                              initial={{ x: 300, opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              transition={{ duration: 0.8, delay: 0.25 }}
                              exit={{ x: 300 }}
                            >
                              {!isMobile && <div className="col-6 col-sm-3 col-md-4 col-lg-4 form-group forget_input_eye">
                                <select
                                  name="gender"
                                  style={{ color: formik.values.gender === "" && "gray", width: "90%" }}
                                  defaultValue=""
                                  className={
                                    "form-select  custom-form-control custom-form-select" +
                                    (formik.errors.gender && formik.touched.gender
                                      ? " is-invalid form-select-is-invalid"
                                      : "")
                                  }
                                  onChange={formik.handleChange}
                                  value={formik.values.gender}
                                  id="insuranceGenderInput"
                                  aria-label="Birth Sex"
                                  onFocus={() => announceErrorMessage('genderError', 'Required')}
                                  onBlur={() => hideErrorMessage('genderError')}
                                >
                                  <option value="" hidden disabled defaultValue="">Birth Sex*</option>
                                  {genderOptions.map(option => (
                                    <option key={option.value} value={option.value} style={{ color: "black" }}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                                {formik.errors.gender && formik.touched.gender && <div className="invalid-feedback" role="alert" id="genderError" aria-live="off">
                                  {" "}
                                  {formik.errors.gender && formik.touched.gender
                                    ? formik.errors.gender
                                    : null}{" "}
                                </div>}
                                {TickCross(formik, "gender", "error-circle-insurance")}
                              </div>}
                              <div className="col-12 col-sm-8 col-md-8 col-lg-8 form-group forget_input_eye" style={{ width: "90%" }}>
                                <DateOfBirth
                                  formik={formik}
                                  fieldName={'dob'}
                                  enabled={true}
                                  id="insurance"
                                  errorMonth={errorMonth}
                                  errorYear={errorYear}
                                  errorDay={errorDay}
                                  setErrorDay={setErrorDay}
                                  setErrorMonth={setErrorMonth}
                                  setErrorYear={setErrorYear}
                                  deskClass={"error-circle-dob"}
                                  mobileClass={"error-circle-dob-mobile"}
                                />
                              </div>
                            </motion.div>}
                            {<motion.div
                              className="row g-1"
                              initial={{ x: 300, opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              transition={{ duration: 0.8, delay: 0.25 }}
                              exit={{ x: 300 }}
                            >
                              {!hasInsurance && <div className="col-6 col-sm-3 col-md-3 col-lg-3 form-group forget_input_eye">
                                <input
                                  type="text"
                                  name="bin"
                                  style={{ width: "90%" }}
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.bin && formik.touched.bin
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="BIN*"
                                  onChange={formik.handleChange}
                                  value={formik.values.bin}
                                  id="insuranceBinInput"
                                  aria-label="BIN"
                                  onFocus={() => announceErrorMessage('binError', 'Required')}
                                  onBlur={() => hideErrorMessage('binError')}
                                />
                                {formik.errors.bin && formik.touched.bin && <div className="invalid-feedback" role="alert" id="binError" aria-live="off">
                                  {" "}
                                  {formik.errors.bin && formik.touched.bin
                                    ? formik.errors.bin
                                    : null}{" "}
                                </div>}
                                {TickCross(formik, "bin", "error-circle-insurance")}
                              </div>}
                              {!hasInsurance && <div className="col-6 col-sm-3 col-md-3 col-lg-3 form-group">
                                <input
                                  type="text"
                                  name="pcn"
                                  style={{ width: "90%" }}
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.pcn && formik.touched.pcn
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="PCN"
                                  onChange={formik.handleChange}
                                  value={formik.values.pcn}
                                  id="insurancePcnInput"
                                  aria-label="PCN"
                                />
                                <div className="invalid-feedback" role="alert">
                                  {" "}
                                  {formik.errors.pcn && formik.touched.pcn
                                    ? formik.errors.pcn
                                    : null}
                                </div>
                              </div>}
                              {!hasInsurance && <div className="col-6 col-sm-3 col-md-3 col-lg-3 form-group forget_input_eye">
                                <input
                                  type="text"
                                  name="groupNumber"
                                  style={{ width: "90%" }}
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.groupNumber &&
                                      formik.touched.groupNumber
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Group*"
                                  onChange={formik.handleChange}
                                  value={formik.values.groupNumber}
                                  id="insuranceGroupNumberInput"
                                  aria-label="Group"
                                  onFocus={() => announceErrorMessage('groupError', 'Required')}
                                  onBlur={() => hideErrorMessage('groupError')}
                                />
                                {formik.errors.groupNumber &&
                                  formik.touched.groupNumber && <div className="invalid-feedback" role="alert" aria-live="off" id="groupError">
                                    {" "}
                                    {formik.errors.groupNumber &&
                                      formik.touched.groupNumber
                                      ? formik.errors.groupNumber
                                      : null}
                                  </div>}
                                {TickCross(formik, "groupNumber", "error-circle-insurance")}
                              </div>}
                              {!hasInsurance && <div className="col-6 col-sm-3 col-md-3 col-lg-3 form-group forget_input_eye">
                                <input
                                  type="text"
                                  style={{ width: "90%" }}
                                  name="memberId"
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.memberId &&
                                      formik.touched.memberId
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Member ID*"
                                  onChange={formik.handleChange}
                                  value={formik.values.memberId}
                                  id="insuranceMemberIdInput"
                                  aria-label="Member ID"
                                  onFocus={() => announceErrorMessage('memberError', 'Required')}
                                  onBlur={() => hideErrorMessage('memberError')}
                                />
                                {formik.errors.memberId &&
                                  formik.touched.memberId && <div className="invalid-feedback" role="alert" id="memberError" aria-live="off">
                                    {" "}
                                    {formik.errors.memberId &&
                                      formik.touched.memberId
                                      ? formik.errors.memberId
                                      : null}
                                  </div>}
                                {TickCross(formik, "memberId", "error-circle-insurance")}
                              </div>}
                              {(!isTab && !isDesktop && !hasInsurance) && <div style={{ marginBottom: -10, marginTop: 6 }}>
                                <span>
                                  {/* insurance Popover to help user identify correct insurance information */}
                                  <PopoverInsurance place={"left"} />
                                </span>
                              </div>}
                              {isPhone && !userOptin &&
                                <OptinSection loading={loading} isPhone={true} checked={checked} setChecked={setChecked} errorOptin={errorOptin} setErrorOptin={setErrorOptin} />
                              }
                            </motion.div>}
                            {isTab && !hasInsurance && <div style={{ marginBottom: -10, marginTop: 6 }}>
                              <span>
                                {/* insurance Popover to help user identify correct insurance information */}
                                <PopoverInsurance place={"left"} />
                              </span>
                            </div>}
                            {isTab && !userOptin &&
                              <OptinSection loading={loading} isPhone={true} checked={checked} setChecked={setChecked} errorOptin={errorOptin} setErrorOptin={setErrorOptin} />
                            }
                            <motion.div
                              className="button_div insurance_inner_main_btn mt-4 g-0"
                              id="cc-info-div-smbt"
                              initial={{ x: 300 }}
                              animate={{ x: 0 }}
                              transition={{ duration: 0.7 }}
                            >
                              <div className="margin-right">
                                <button
                                  className="btn_success"
                                  type="submit"
                                >
                                  Submit
                                </button>
                              </div>
                              {/* <div className="margin-right webView">
                                <button
                                  className="btn_default NIbutton "
                                  type="button"
                                  onClick={() => CopayProgram()}
                                >
                                  No&nbsp;insurance
                                </button>
                              </div> */}
                              <div className="margin-right webView">
                                <button
                                  type="button"
                                  className="btn_default NIbutton"
                                  onClick={() => CopayProgram()}
                                >
                                  Skip
                                </button>
                              </div>
                              <div className="row mobileView">
                                {/* <div className="col-6">
                                  <button
                                    className="btn_default "
                                    type="button"
                                    onClick={() => CopayProgram()}
                                  >
                                    No&nbsp;insurance
                                  </button>
                                </div> */}
                                <div className="col-6">
                                  <button
                                    type="button"
                                    className="btn_default "
                                    onClick={() => CopayProgram()}
                                  >
                                    Skip
                                  </button>
                                </div>
                              </div>
                            </motion.div>
                          </motion.div>
                          {isDesktop && !hasInsurance && <div>
                            <span>
                              {/* insurance Popover to help user identify correct insurance information */}
                              <PopoverInsurance place={"left"} />
                            </span>
                          </div>}
                          {isDesktop && !userOptin &&
                            <OptinSection loading={loading} isPhone={false} checked={checked} setChecked={setChecked} errorOptin={errorOptin} setErrorOptin={setErrorOptin} />
                          }
                          <motion.div
                            className="button_div insurance_inner_main_btn2  g-0"
                            id="cc-info-div-smbt"
                            initial={{ x: 300 }}
                            animate={{ x: 0 }}
                            transition={{ duration: 0.7 }}
                          >
                            <div className="margin-right">
                              <button
                                className="btn_success"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                            {/* <div className="margin-right webView">
                              <button
                                className="btn_default NIbutton "
                                type="button"
                                onClick={() => CopayProgram()}
                              >
                                No&nbsp;insurance
                              </button>
                            </div> */}
                            <div className="margin-right webView">
                              <button
                                className="btn_default NIbutton"
                                type="button"
                                onClick={() => CopayProgram()}
                              >
                                Skip
                              </button>
                            </div>
                            {/* for mobile View */}
                            <div className="row mobileView">
                              {/* <div className="col-6">
                                <button
                                  className="btn_default "
                                  type="button"
                                  onClick={() => CopayProgram()}
                                >
                                  No&nbsp;insurance
                                </button>
                              </div> */}
                              <div className="col-12">
                                <button
                                  className="btn_default "
                                  type="button"
                                  onClick={() => CopayProgram()}
                                >
                                  Skip
                                </button>
                              </div>
                            </div>
                          </motion.div>
                        </div>
                      </div>
                    </form>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobileView">
            <div className="row g-0">
              <div className="col-12 col-sm-12 col-md-0 col-lg-0 bottom">
                <div className="dot-main">
                  <span className="dot"></span>
                  <span className="dot dotActive"></span>
                  <span className="dot"></span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </AnimatePresence>
    </div>
  );
};


export default Insurance;

// Optin Section for inurance information page
const OptinSection = ({ loading, isPhone, isTab, checked, setChecked, errorOptin, setErrorOptin }) => {

  return (
    <div className="hcdl-section mt-3">
      {loading &&
        <div style={{ zIndex: 1001, position: "absolute", right: isPhone || isTab ? "50%" : "31%" }}>
          <Spinner animation="border" variant="primary" />
        </div>
      }
      <div className={`col-12 col-sm-12 col-md-12 col-lg-12 mb-1`}>
        <h1 style={{ fontSize: 20, color: "#4b4c4d", fontWeight: 600, letterSpacing: 0.2, lineHeight: 1.1 }}>
          Do you want to keep saving?
        </h1>
      </div>
      <p>Subscribe and we will send you the latest and best ways to save on prescriptions.</p>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12" >
        <input type="checkbox" aria-labelledby={""} name='terms' checked={checked}
          className="p-1 mr-2" onChange={(e) => {
            setChecked(e.target.checked);
            if (e.target.checked) setErrorOptin(false);
          }
          }
          id="optintermsCheckBox" aria-label="terms"
        />
        <p style={{ marginLeft: 4, display: "inline", color: errorOptin ? "red" : "" }}>
          By submitting this form and signing up for texts, you consent to receive text messages from RxLink at the number provided,
          including message sent by autodialer.
          Consent is not condition of purchase. Msg & data rates may apply. Msg frequency varies.
          Unsubscribe at any time by replying STOP. Reply HELP for Help. I read and agree with the RxLink <a href="https://rxlink.com/terms/" target="_blank" rel="noopener">Terms of Service </a>
          and <a href="https://rxlink.com/privacy-policy/" target="_blank" rel="noopener">Privacy Policy</a>.
        </p>
      </div>
    </div>
  )
}