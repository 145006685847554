import React from "react";
import * as Sentry from "@sentry/react";

class CustomErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Don't change state to avoid showing fallback UI
    return { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Send error to Sentry
    Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    return this.props.children; // Always render the original UI
  }
}

export default CustomErrorBoundary;
