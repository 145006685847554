import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingBar from 'react-top-loading-bar'
import "../src/assets/css/styles.css";
import Footer from "./components/partials/Footer";
import AutoLogout from "./utilities/AutoLogOut";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AnimatePresence } from "framer-motion";
import { RoutesWithAnimation } from "./AnimatedRoute";
import Drawer from "./components/Drawer";
import { httpClientJava } from "./environment";
import { setIP } from "./reducers/global";
import Loader from "./components/Loader";
import { useGetUser } from "./utilities/useGetUser";
import { setProgress } from "./reducers/global";


function App() {

  // State to store the current position details
  const [ownPosition, setOwnPosition] = useState(null);

  // Fetching session data for latitude, longitude, and postal code from sessionStorage
  const sessionLatitude = window.sessionStorage.getItem("latitude");
  const sessionLongitude = window.sessionStorage.getItem("longitude");
  const sessionPostal = window.sessionStorage.getItem("postalCode");

  // Fetching user details using a custom hook
  const userDetail = useGetUser();

  // Default total savings from user details
  const totalSavings = userDetail?.totalSavings || 0;

  // Fetching IP, loading states, pharmacy update status and progress from Redux store
  const ip = useSelector((state) => state.global.ip);
  const animatedLoading = useSelector((state) => state.global.animatedLoading);
  const isUpdatingPharmacy = useSelector((state) => state.global.isUpdatingPharmacy);
  const progress = useSelector((state) => state.global.progress);

  // Action dispatcher for Redux
  const dispatch = useDispatch();

  // Function to set the user IP in the Redux store
  const setIp = (ip) => {
    dispatch(setIP(ip));
  }

  // Effect to update the IP address from user details into Redux
  useEffect(() => {
    if (userDetail?.proxyIpAddress)
      setIp(userDetail.proxyIpAddress)
  }, [userDetail?.proxyIpAddress])

  // Effect to trigger fetching of zip code when IP is available
  useEffect(() => {
    if (ip != null && ip != "") {
      getZipCode();
    }
  }, [ip])

  // Function to fetch the zip code and position information based on IP
  const getZipCode = async () => {
    // If there's no session postal code, request for IP info
    if (sessionPostal == null) {
      await httpClientJava.post("/GetIpInfo", {
        ipAddress: ip
      }).then((response) => {
        const res = JSON.parse(response.data.data); // Parse the response data
        setOwnPosition({
          ip: ip,
          lat: res.latitude,
          lng: res.longitude,
          showMap: true,
          postalCode: res.postal,
          responseZIP: res
        });
      }).catch((err) => {
        // If the request fails, set a default position
        setOwnPosition({
          ip: ip,
          lat: 33.753746,
          lng: -84.386330,
          showMap: true,
          postalCode: 30301,
          responseZIP: {}
        });
      })
    }
    else {
      // If session postal exists, use session value
      setOwnPosition({
        ip: ip,
        lat: sessionLatitude,
        lng: sessionLongitude,
        showMap: true,
        postalCode: sessionPostal,
        responseZIP: {}
      });
    }
  }

    // Fallback timeout to hide the loading bar after 1 minute
    useEffect(() => {
      if (progress > 0 && progress < 100) {
        // Set a fallback timeout to complete the loading bar after 1 minute
        const timeoutId = setTimeout(() => {
          dispatch(setProgress(100));  // Reset the progress in Redux store
        }, 40000); // 40 seconds fallback
  
        return () => clearTimeout(timeoutId); // Cleanup the timeout
      }
    }, [progress]);

  return (
    <div>
      {/* Show loader while loading or updating pharmacy */}
      {(animatedLoading || isUpdatingPharmacy) && <Loader />}
      <div className="appContainer">
        {/* Loading progress bar */}
      <LoadingBar
        color='#22A699'
        progress={progress}
        height={4}
        // onLoaderFinished={() => dispatch(setProgress(0))}
      />
        <div className="heading">
        </div>
        <div className="">
          <HashRouter>
            {/* Toast notifications container */}
            <ToastContainer />
            <AutoLogout>
              {/* Provide animation context */}
              <LocationProvider>
                {/* Drawer component with total savings */}
                <Drawer totalSavings={totalSavings} />
                {/* Routes with animation and passing position details */}
                <RoutesWithAnimation ownPosition={ownPosition} setOwnPosition={setOwnPosition} setIp={setIp} />
              </LocationProvider>
            </AutoLogout>
          </HashRouter>
        </div>
        {/* Footer component */}
        <Footer />
      </div>
    </div>
  );
}
export default App;

// LocationProvider component that wraps around AnimatePresence to handle route transitions
const LocationProvider = ({ children }) => {
  return <AnimatePresence mode="wait">
      <div key={window.location.pathname}>{children}</div>
    </AnimatePresence>;
}
