import React, { useState, useRef, useEffect } from 'react';
import InfoSvg from "./../assets/images/info.svg";
import { Popover } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Overlay from 'react-bootstrap/Overlay';
import closeIcon from "./../assets/images/RxLink assets/close icon.svg";
import Modal from "react-bootstrap/Modal";
import { useWindowWidth } from './searchPharmacy/useWindowWidth';

const PopoverInsurance = ({ place = "auto" }) => {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const popoverRef = useRef(null);

    const { windowWidth } = useWindowWidth();

    const isTab = windowWidth > 766 && windowWidth <= 1080;
    const isDesktop = windowWidth > 1080;
    const isPhone = (isTab == false && isDesktop == false);

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    const handleClickOutside = (event) => {
        if (
            popoverRef.current &&
            !popoverRef.current.contains(event.target) &&
            !ref.current.contains(event.target)
        ) {
            setShow(false);
        }
    };

    const handleScroll = () => {
        if (popoverRef.current) {
            const { top, bottom, left, right } = popoverRef.current.getBoundingClientRect();
            const isOutsidePopover = (
                window.scrollY < top ||
                window.scrollY > bottom ||
                window.scrollX < left ||
                window.scrollX > right
            );

            if (isOutsidePopover) {
                setShow(false);
            }
        }
    };

    useEffect(() => {
        if (show && isDesktop) {
            document.addEventListener('pointerdown', handleClickOutside);
            window.addEventListener('scroll', handleScroll);
        } else {
            document.removeEventListener('pointerdown', handleClickOutside);
            window.removeEventListener('scroll', handleScroll);
        }

        return () => {
            document.removeEventListener('pointerdown', handleClickOutside);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [show, isDesktop]);

    return (
        <span ref={ref}>

            {
                (isDesktop || isTab) ?
                    <span style={{ fontSize: 13, fontWeight: 400, paddingLeft : place == "right" ? 18 : 0 }} >Where do I find my insurance information?
                        <img
                            tabIndex={0}
                            src={InfoSvg}
                            style={{ height: 18, width: 18, marginTop: -2, cursor: "pointer" }}
                            alt="insurance Information"
                            onClick={(e) => handleClick(e)}
                        />
                    </span>
                    :
                    <a href="javascript:void(0)" onClick={(e) => handleClick(e)} style={{ fontSize: 13, fontWeight: 400 }} className='mobile-only-popover'>Where do I find my insurance information?</a>
            }
            {
                isDesktop ?
                    <Overlay
                        show={show}
                        target={target}
                        placement={place}
                        // container={ref}
                        containerPadding={30}
                        rootClose={true}
                        ref={popoverRef}
                    >
                        <Popover style={{ maxWidth: '650px', minWidth: '350px', width: 'auto' }}>
                            <Popover.Body style={{ overflowY: "auto", maxHeight: '92vh', fontFamily: "Montserrat" }}>
                                <PopoverInsuranceContent setShow={setShow} isPhone={isPhone} isDesktop={isDesktop} />
                            </Popover.Body>
                        </Popover>
                    </Overlay>
                    :
                    <Modal
                        show={show}
                        centered
                        keyboard={false}
                        dialogClassName="modal-custom-className"
                        aria-labelledby="example-custom-modal-styling-title"
                        id="example-custom-modal-styling-title"
                    >
                        <Modal.Body ref={popoverRef} id="example-modal-sizes-title-lg">
                            <div style={{ padding: 2, letterSpacing: 0.1 }}>
                                <PopoverInsuranceContent setShow={setShow} isPhone={isPhone} isDesktop={isDesktop} />
                            </div>
                        </Modal.Body>
                    </Modal>
            }
        </span>
    )
}

export default PopoverInsurance;


const PopoverInsuranceContent = ({ setShow, isPhone, isDesktop }) => {
    return (
        <div>
            <div className='card-top-info'>
                Where to find your pharmacy information
                <span style={{ position: "absolute", right: 4, top: 0 }}>
                    <img src={closeIcon} style={{ height: 32, cursor: "pointer" }} onClick={() => setShow(false)} />
                </span>
            </div>
            <p style={{ marginTop: 8 }} className='card-text-style'>
                In order for RxLink to compare our discounts to your insurance and recommend the lowest price,
                we need to identify your insurance. The identifiers for your prescription benefit may be on your
                health insurance card or on a separate prescription benefit card.
            </p>
            <p style={{ marginTop: -10 }} className='card-text-style'>
                The information will have upto four fields: <span style={{ fontWeight: "bold" }}>RxBIN, RxGRP, RxPCN and ID</span>
            </p>
            <p style={{ marginTop: -10 }} className='card-text-style'>
                Here's an example on a health insurance card that has both health benefit and pharmacy benefit information. The pharmacy benefit information preceded by "Rx".
                Often you combine this "Rx" information with the same ID as your health insurance. This information may also be on the back of your health insurance card.
            </p>
            <p style={{ marginTop: -6, marginBottom: 3 }} className='card-top-info'>Health Insurance Card</p>
            <div className={isDesktop ? 'card-body-style' : 'card-body-style-mob'}>
                <div className='card-top-heading'>Your Health Insurer</div>
                <div style={{ color: "white", fontSize: 13 }}>Your Health Plan - Gold</div>
                <div>
                    <span className='card-line-item'>GRP:</span><span className={isDesktop ? 'card-line-item-value' : 'card-line-item-value-mob'}>347833-44-3344</span>
                    <br />
                    <span className='card-line-item'>Issuer:</span><span className={isDesktop ? 'card-line-item-value' : 'card-line-item-value-mob'}>37439 3842003447</span>
                    <br />
                    <span className='card-line-item' style={{ background: "#eb6c6c", marginLeft: -4, paddingLeft: 4 }}>ID:</span><span style={{ background: "#eb6c6c", paddingLeft: 4 }} className={isDesktop ? 'card-line-item-value' : 'card-line-item-value-mob'}>W234 75494</span> <span style={{ width: 100, background: "#eb6c6c", paddingLeft: 20 }} className='card-line-item'>RxBin:<span className='card-line-item-value'>75494</span></span>
                    <br />
                    <span className='card-line-item'>Sub Name:</span><span className={isDesktop ? 'card-line-item-value' : 'card-line-item-value-mob'}>John Smith</span><span style={{ width: 100, background: "#eb6c6c", paddingLeft: 14 }} className='card-line-item'>RxPCN:<span className='card-line-item-value'>75494</span></span>
                    <br />
                    <span className='card-line-item'>Member Name:</span><span className={isDesktop ? 'card-line-item-value' : 'card-line-item-value-mob'}>John Smith</span><span style={{ width: 100, background: "#eb6c6c", paddingLeft: 4 }} className='card-line-item'>RxGroup:<span className='card-line-item-value'>75494</span></span>
                </div>
            </div>

            <p style={{ marginTop: 8, marginBottom: 6 }} className='card-text-style'>
                Here's an example of separate prescription benefit card. The only information on this card relates to your prescription information.
            </p>

            <div className={isDesktop ? 'card-body-style' : 'card-body-style-mob'}>
                <div className='card-top-heading'>Your Health Insurer</div>
                <div style={{ marginTop: 12 }}>
                    <span style={{ width: 70 }} className='card-line-item'>Issuer:</span><span style={{ width: 126, }} className='card-line-item-value'>37439 3842003447</span>
                    <br />
                    <span style={{ width: 70, background: "#eb6c6c", marginLeft: -4, paddingLeft: 4 }} className='card-line-item'>RxBin:</span><span style={{ width: 90, background: "#eb6c6c", paddingLeft: 4 }} className='card-line-item-value'>019876</span>
                    <br />
                    <span className='card-line-item' style={{ width: 70, background: "#eb6c6c", marginLeft: -4, paddingLeft: 4, marginTop: 4 }}>RxPCN:</span><span style={{ width: 90, background: "#eb6c6c", paddingLeft: 4 }} className='card-line-item-value'>019666</span>
                    <br />
                    <span className='card-line-item' style={{ width: 70, background: "#eb6c6c", marginLeft: -4, paddingLeft: 4, marginTop: 4 }}>RxGroup:</span><span style={{ width: 90, background: "#eb6c6c", paddingLeft: 4 }} className='card-line-item-value'>019879</span>
                    <br />
                    <span className='card-line-item' style={{ width: 70, background: "#eb6c6c", marginLeft: -4, paddingLeft: 4, marginTop: 4 }}>ID:</span><span style={{ width: 90, background: "#eb6c6c", paddingLeft: 4 }} className='card-line-item-value'>0123456789</span>
                </div>
            </div>
            <p style={{ marginTop: 12 }} className='card-text-style'>
                For both cards, you may not have all four fields (RxBin, RxPCN, RxGroup or ID) as some insurers don't required them. If so, input as many fields as you have on your card.
                <br />
                If you can't find your Rx insurance information, call your insurer. There number is usually listed as "Member Services" on your <span style={{ fontWeight: "bold" }}>ID card.</span>
            </p>
            <p style={{ marginTop: 0 }} className='card-text-style'>
            </p>
        </div>
    )
}