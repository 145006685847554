import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { useDispatch } from 'react-redux';
import { MotionTextH2 } from '../motion/MotionText';
import { MotionButton } from "../motion/MotionButton";
import { motion } from "framer-motion"
import { fetchSavingHistory, setSavingsHistory } from '../../reducers/medication';
import { store } from "../../store";
import cancel from "../../assets/images/RxLink assets/new assets/regular x.svg"
import { useGetUser } from '../../utilities/useGetUser';

export default function Savings() {
    const dispatch = useDispatch();
    const [savingsToRender, setSavingToRender] = useState([]);
    const [confirmModal, setConfirmModal] = useState(false)

    const reduxState = store.getState();
    const savings = reduxState.medication.savingHistory;

    const userDetail = useGetUser();

    useEffect(() => {
        const getHistory = () => {
            dispatch(fetchSavingHistory(userDetail?.appUserSeqNo))
                .unwrap()
                .then((res) => {
                    console.log({ responseSaving: res })
                    dispatch(setSavingsHistory(res.data))
                })
                .catch((er) => {
                    console.log(er)
                })
                .finally(e => {
                })
        }
        if (userDetail) {
            getHistory();
        }
    }, [])

    useEffect(() => {
        console.log({ savings });
        if (savings?.status == "OK" && savings?.data?.length > 0) {
            let savingsData = [];
            const filled = savings.data.filter((item) => item.claim_Status == "filled");
            filled.map((saving, index) => {

                if (index == 0) {
                    let model = {
                        id: index,
                        drugsName: saving.drugName,
                        dateFilled: saving.date_filled,
                        price: saving.copay_amount
                    }
                    savingsData.push(model);
                }

                else {
                    let checkDate = savingsData.filter((el, i) => el.dateFilled == saving.date_filled);
                    if (checkDate.length > 0) {
                        savingsData[checkDate[0].id].drugsName = savingsData[checkDate[0].id].drugsName + ", " + saving.drugName;
                        savingsData[checkDate[0].id].price = savingsData[checkDate[0].id].price + saving.copay_amount;
                    }
                    else {
                        let model = {
                            id: index,
                            drugsName: saving.drugName,
                            dateFilled: saving.date_filled,
                            price: saving.copay_amount
                        }
                        savingsData.push(model);
                    }
                }
            });
            setSavingToRender(savingsData);
        }
    }, [savings])

    useEffect(() => {

        if (!savings || savings.status !== "OK" || !savings.data || savings.data.length === 0) {
            return;
        }
        const filledSavings = savings.data;

        if (filledSavings.length === 0) {
            return;
        }

        const savingsData = filledSavings.reduce((accumulator, saving, index) => {
            const existingIndex = accumulator.findIndex(el => el.dateFilled === saving.dateFilled);

            if (existingIndex !== -1) {
                // Update existing entry.
                accumulator[existingIndex].drugsName += `, ${saving.drugName}`;
                accumulator[existingIndex].price += saving.copayAmount;
            } else {
                // Create a new entry.
                accumulator.push({
                    id: index,
                    drugsName: saving.drugName,
                    dateFilled: saving.dateFilled,
                    price: saving.copayAmount,
                    date: `${saving.dateFilled.substring(6, 8)}/${saving.dateFilled.substring(4, 6)}/${saving.dateFilled.substring(0, 4)}`
                });
            }

            return accumulator;
        }, []);

        setSavingToRender(savingsData.sort((a, b) => new Date(...b.date.split("/").reverse()) - new Date(...a.date.split("/").reverse())));
    }, [savings]);

    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">

                        <div className="medicine_icon pharma_icon" >
                            {/* <MotionIcon> */}
                            <a className='txtDecoration' href='javascript:void(0)' onClick={() => window.history.back()}>
                                <i className="fas fa-angle-left" alt="" aria-label="Go Back"></i>
                            </a>
                            {/* </MotionIcon> */}
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="medication-heading" role='alert'>
                            <MotionTextH2 text="Savings history" duration={0.03} delay={0.15} />
                        </div>
                    </div>
                </div>


                {
                    savingsToRender.length > 0 ? savingsToRender.map((el, i) => {
                        return (
                            <div className="row saving-item">
                                <span className="col-6 fw-bold">
                                    {el.drugsName}
                                    {true && <br />} {true && <span style={{ fontSize: 12 }} className="fw-normal">
                                        Redeemed on {el.date}
                                    </span>}
                                    {true && <br />}
                                    <span style={{ fontSize: 12 }} className="fw-normal">$ {el.price.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })} saved</span>
                                </span>
                                <span className="col-6 icon-forward">
                                    <MotionButton>
                                        <motion.button
                                            className="btn_success"
                                            style={{ minWidth: 136, maxWidth: 136, float: "right" }}
                                            // onClick={() => {
                                            //     setConfirmModal(true)
                                            // }}
                                            whileTap={{
                                                scale: 0.9,
                                                transition: { duration: 0.2 },
                                                opacity: 1,
                                                type: "tween"
                                            }}
                                        >
                                            View savings
                                        </motion.button>
                                    </MotionButton>
                                </span>

                            </div>
                        )
                    }) : <span style={{ display: "flex", justifyContent: "center", padding: 20 }}>No savings available</span>
                }
            </div>
            {/* <ViewSavingModal isOpen={confirmModal} handleClose={() => setConfirmModal(false)} /> */}
        </div>
    )
}

const ViewSavingModal = ({ isOpen, handleClose }) => {
    return (
        <Modal show={isOpen} onHide={handleClose} keyboard={false} centered style={{ width: "92%", justifyContent: "center" }}>
            <Modal.Body className=""  >
                <div className='container' style={{ maxWidth: "100%", padding: 20 }}>
                    <div className="row">
                        <div className='col-8'><p style={{ paddingTop: 6, fontSize: 18 }}>Abandon current savings?</p></div>
                        <div className='col-4'>
                            <button
                                className="crossButton"
                                onClick={handleClose}
                                type="button"
                            >
                                <img src={cancel} />
                            </button>
                        </div>

                    </div>
                    <div className="">
                        <div className="hrDiv hrDivpadding"></div>
                    </div>
                    <div>
                        <p style={{ fontSize: 16 }}>Viewing your past savings will erase any savings currently selected would you like to proceed anyway?</p>
                    </div>
                    <div className="row">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="mt-20">
                                {/* <MotionButton> */}
                                <button
                                    className="btn_success"
                                    onClick={handleClose}
                                >
                                    Continue
                                </button>
                                {/* </MotionButton> */}
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="mt-20">
                                {/* <MotionButton> */}
                                <button
                                    type="button"
                                    className="btn_default"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                {/* </MotionButton> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
