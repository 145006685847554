export const ariaAnnounce = (message, id) => {
  const div = document.createElement('div');
  const ele = id ? document.getElementById(id) : null;

  div.style.position = 'absolute';
  div.style.left = '-10000px';
  div.style.width = '1px';
  div.style.height = '1px';
  div.style.overflow = 'hidden';

  div.setAttribute('aria-live', 'assertive');
  div.setAttribute('aria-atomic', 'true');
  div.setAttribute('role', 'alert');

  if (ele) {
    ele.appendChild(div);
  } else {
    document.body.appendChild(div);
  }

  setTimeout(() => {
    div.innerText = message;
  }, 300);

  setTimeout(() => {
    if (div?.parentNode) {
      div?.parentNode?.removeChild(div);
    }
  }, 7000);
};

export default ariaAnnounce;
